import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const UNREALSVC_GATEWAY_URL =
  'https://sc627b7uy5.execute-api.ap-southeast-2.amazonaws.com/prod';
const required = 'This field is required';

export default function FormContact() {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    try {
      await fetch(UNREALSVC_GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      setSubmitted(true);
      reset();
    } catch (errors) {
      setError(
        'submit',
        'submitError',
        `Oops! There seems to be an issue! ${errors.message}`
      );
    }
  };

  const showSubmitError = msg => <p className="msg-error">{msg}</p>;

  const showThankYou = (
    <div className="msg-confirm">
      <p>Thank you for your request. We will be in touch to finalise the install</p>
      <button type="button" onClick={() => setSubmitted(false)}>
        Send another request?
      </button>
    </div>
  );

  const showForm = (
    <div className="container-fluid">
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <div className="page contact-page">

          <input
            id="company"
            type="text"
            placeholder="Your Business' Name <Required>"
            // aria-invalid={errors.company ? "true" : "false"}
            {...register('company', { required: true, maxLength: 100 })}
          />
          {errors.name && (
            <span className="msg-error">
            We need to know your business trading name
            </span> 
          )}
          <br />

          <input
            type="abn"
            placeholder="Business' ABN or ACN"
            {...register('abn', {
              required: false,
              pattern: [0 - 9],
              minLength: 9,
              maxLength: 11
            })}
          />
          {errors.abn && (
            <span className="msg-error"> Please enter 9 digit ACN or 11 digit ABN (no spaces)</span>
          )}
          <br />

          <input
            id="fulladdress"
            type="text"
            placeholder="Business' full install address <Required>"
            // aria-invalid={errors.fulladdress ? "true" : "false"}
            {...register('fulladdress', { required: true, maxLength: 150 })}
          />
          {errors.fulladdress && (
            <span className="msg-error">
            Please provide an installation address
            </span> 
          )}
          <br />

          <input
            id="owner"
            type="text"
            placeholder="Business Owner's Name <Required>"
            // aria-invalid={errors.owner ? "true" : "false"}
            {...register('owner', { required: true, maxLength: 100 })}
          />
          {errors.name && (
            <span className="msg-error">
            Please tell us your name
            </span> 
          )}
          <br />

          <input
            type="ownertel"
            placeholder="Owner's contact number <Required>"
            {...register('ownertel', {
              required: true,
              pattern: [0 - 9],
              minLength: 8,
              maxLength: 10
            })}
          />
          {errors.ownertel && (
            <span className="msg-error"> Your contact number, no brackets or spaces. Type 0400000000 if only email</span>
          )}
          <br />

          <input
            type="owneremail"
            placeholder="Owner's Email address <Required>"
            {...register('owneremail', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.owneremail && (
            <span className="msg-error"> We need an email to respond to</span>
          )}
          <br />

          <input
            id="machinetype"
            type="text"
            placeholder="Preferred machine type, if known"
            {...register('machinetype', { required: false, maxLength: 100 })}
          />
          {errors.machinetype && (
            <span className="msg-error">
            Error
            </span> 
          )}
          <br />

          <input
            id="referrer"
            type="text"
            placeholder="Salesperson, referrer or website you found us through"
            {...register('referrer', { required: false, maxLength: 100 })}
          />
          {errors.machinetype && (
            <span className="msg-error">
            Error
            </span> 
          )}
          <br />
          
          <textarea
            placeholder="Type any further details or a message here"
            {...register('message', { required: false })}
          />
          {errors.message && (
            <span className="msg-error">
              {' '}
              Error{' '}
            </span>
          )}
          <br />
          <div className="submit-wrapper">
            <button type="submit" disabled={isSubmitting}>
              Submit Installation Request
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div className="page signup-page">
      <div className="msg-confirm">
      <section>
        <br />
        <br />
        <br />
        <br />  
        <h2form> Request a no-cost machine for your store</h2form>
        <br />
        <br />
        <h4form>Remember: Machines placed outside the store perform the best & also attract new customers</h4form>
        <br />
        <br />
        <br />        
        {errors && errors.submit && showSubmitError(errors.submit.message)}
        </section>
      </div>
      <div className="form-side">{submitted ? showThankYou : showForm}</div>
    </div>
  );
}
