import React, { useState } from 'react';
import {useForm} from 'react-hook-form';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import FormSignup from '../components/Contact/FormSignup';
import MapContact from '../components/Contact/MapContact';

const Signup = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout bodyClass="page-default-single">
      <div className="Title">
        <div className="row justify-content-start">
        <div className="col-12 col-md-7 col-lg-6 order-1 order-md-1">
            </div>
            </div>
        </div>
      <div className="container">
        <div className="row justify-content-centre">
            <div className="col-10 col-md-6 col-lg-5 order-2 order-md-2 position-relative">
            <br/>
            <FormSignup />
           </div>
           <div className="col-12 col-md-7 col-lg-6 order-1 order-md-1 position-relative">
            <span> <h1> </h1> </span>
            <br/>
            <br/>
            <span> <h1> </h1> </span>
              <h4form>How it works</h4form>
              <br/>
              <br/>
                <h4> Arranging for install:</h4>
                <ul>
                  <li>Send us your details on the form</li>
                  <li>We'll call, email or visit to finalise location & machine type/s</li>
                  <li>Machine gets installed as agreed</li>
                </ul>  
                <br/>
                <h4> That's it! Once in place:</h4>
                <ul>
                  <li>Our service agents visit periodically to service</li>
                  <li>We give you 25% of sales everytime we cash</li>
                  <li>Customer contacts us directly for any issues so it doesn't waste your time</li>
                </ul>
              <br/>
              <img alt="signup benefits" src={data.markdownRemark.frontmatter.intro_image} width="auto" height="auto"/>
           </div>
          <div className="col-12 col-md-7 col-lg-6 order-3 order-md-3 position-relative">
            <MapContact/>
            </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        intro_image
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Signup;
